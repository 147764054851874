import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as investmentsReducer } from '../slices/investments';
import { reducer as managementsReducer } from '../slices/managements';
import { reducer as administrationsReducer } from '../slices/administrations';
import { reducer as pammsReducer } from '../slices/pamms';
import { reducer as alertsReducer } from '../slices/alerts';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  investments: investmentsReducer,
  managements: managementsReducer,
  administrations: administrationsReducer,
  pamms: pammsReducer,
  alerts: alertsReducer
});

export default rootReducer;
