import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  investments: [],
  investmentDetail: {},
  investmentOpenPositions: [],
  investmentClosedPositions: [],
  investmentHistory: [],
  investmentInvestors: [],
  investmentRequests: []
};

const slice = createSlice({
  name: 'myInvestments',
  initialState,
  reducers: {
    getInvestments(state, action) {
      const investments = action.payload;
      state.investments = investments;
    },
    getInvestmentDetail(state, action) {
      const investmentDetail = action.payload;
      state.investmentDetail = investmentDetail;
    },
    getInvestmentClosedPositions(state, action) {
      const investmentClosedPositions = action.payload;
      state.investmentClosedPositions = investmentClosedPositions;
    },
    getInvestmentOpenPositions(state, action) {
      const investmentOpenPositions = action.payload;
      state.investmentOpenPositions = investmentOpenPositions;
    },
    getInvestmentHistory(state, action) {
      const investmentHistory = action.payload;
      state.investmentHistory = investmentHistory;
    },
    getInvestmentInvestors(state, action) {
      const investmentInvestors = action.payload;
      state.investmentInvestors = investmentInvestors;
    },
    getInvestmentRequests(state, action) {
      const investmentRequests = action.payload;
      state.investmentRequests = investmentRequests;
    },
    resetInvestmentDetails(state) {
      state.investmentDetail = initialState.investmentDetail;
      state.investmentOpenPositions = initialState.investmentOpenPositions;
      state.investmentOpenPositions = initialState.investmentOpenPositions;
      state.investmentClosedPositions = initialState.investmentClosedPositions;
      state.investmentHistory = initialState.investmentHistory;
      state.investmentInvestors = initialState.investmentInvestors;
      state.investmentRequests = initialState.investmentRequests;
    }
  }
});

export const { reducer } = slice;

export const getInvestments = (onLoading) => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/pamms/investments');
    dispatch(slice.actions.getInvestments(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestments(initialState.investments));
    onLoading();
  }
};

export const getInvestmentDetail = (pammId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/investments/detail`);
  dispatch(slice.actions.getInvestmentDetail(response.data));
};

export const getInvestmentClosedPositions = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/investments/positions/closed`);
    dispatch(slice.actions.getInvestmentClosedPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestmentClosedPositions(initialState.investmentClosedPositions));
    onLoading();
  }
};

export const getInvestmentOpenPositions = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/investments/positions/open`);
    dispatch(slice.actions.getInvestmentOpenPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestmentOpenPositions(initialState.investmentOpenPositions));
    onLoading();
  }
};

export const getInvestmentHistory = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/investments/history`);
    dispatch(slice.actions.getInvestmentHistory(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestmentHistory(initialState.investmentHistory));
    onLoading();
  }
};

export const getInvestmentInvestors = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/investments/investors`);
    dispatch(slice.actions.getInvestmentInvestors(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestmentInvestors(initialState.investmentInvestors));
    onLoading();
  }
};

export const getInvestmentRequests = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/investments/requests`);
    dispatch(slice.actions.getInvestmentRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestmentRequests(initialState.investmentRequests));
    onLoading();
  }
};

export const resetInvestmentDetails = () => async (dispatch) => {
  dispatch(slice.actions.resetInvestmentDetails());
};

export default slice;
