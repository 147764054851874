import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import BellIcon from '../icons/Bell';
import { useSelector } from '../store';

const iconsMap = {
  DEPOSIT: MonetizationOn,
  WITHDRAWAL: RemoveCircle,
  CLOSE_ACCOUNT: RemoveCircle
};

const iconColorsMap = {
  DEPOSIT: 'success.main',
  WITHDRAWAL: 'warning.main',
  CLOSE_ACCOUNT: 'error.main'
};

const textMap = {
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
  CLOSE_ACCOUNT: 'Close account'
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { administrationNotifications } = useSelector((state) => state.administrations);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={administrationNotifications.length}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notifications
          </Typography>
        </Box>
        {administrationNotifications.length === 0
          ? (
            <Box sx={{ p: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                There are no notifications
              </Typography>
            </Box>
          )
          : (
            <>
              <List disablePadding>
                {administrationNotifications.map((notification) => {
                  const Icon = iconsMap[notification.request_operation];

                  return (
                    <ListItem
                      divider
                      key={notification.request_id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: iconColorsMap[notification.request_operation],
                            color: 'primary.contrastText'
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={(
                          <Link
                            color="textPrimary"
                            sx={{ cursor: 'pointer' }}
                            underline="none"
                            variant="subtitle2"
                            component={RouterLink}
                            to={`/pamms/administrations/${notification.pamm_id}/details`}
                          >
                            {`${textMap[notification.request_operation]} requested in the '${notification.pamm_name}' account`}
                          </Link>
                        )}
                        secondary={`Requested by: ${notification.investment_name} (account: ${notification.investment_login})`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
