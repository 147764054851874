import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Home = Loadable(lazy(() => import('./pages/Home')));

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

const Pamms = Loadable(lazy(() => import('./pages/Pamms')));
const Investments = Loadable(lazy(() => import('./pages/Investments')));
const InvestmentDetails = Loadable(lazy(() => import('./pages/InvestmentDetails')));

const Managements = Loadable(lazy(() => import('./pages/Managements')));
const ManagementDetails = Loadable(lazy(() => import('./pages/ManagementDetails')));

const Administrations = Loadable(lazy(() => import('./pages/Administrations')));
const AdministrationDetails = Loadable(lazy(() => import('./pages/AdministrationDetails')));
const AdministrationInvestmentDetails = Loadable(lazy(() => import('./pages/AdministrationInvestmentDetails')));

const Join = Loadable(lazy(() => import('./pages/Join')));

const routes = [
  {
    path: 'pamms',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <Pamms />
        )
      },
      {
        path: '/:pammId/join',
        element: (
          <Join />
        )
      },
      {
        path: '/investments',
        element: (
          <AuthGuard>
            <Investments />
          </AuthGuard>
        )
      },
      {
        path: '/investments/:pammId/details',
        element: (
          <AuthGuard>
            <InvestmentDetails />
          </AuthGuard>
        )
      },
      {
        path: '/managements',
        element: (
          <AuthGuard>
            <Managements />
          </AuthGuard>
        )
      },
      {
        path: '/managements/:pammId/details',
        element: (
          <AuthGuard>
            <ManagementDetails />
          </AuthGuard>
        )
      },
      {
        path: '/administrations',
        element: (
          <AuthGuard>
            <Administrations />
          </AuthGuard>
        )
      },
      {
        path: '/administrations/:pammId/details',
        element: (
          <AuthGuard>
            <AdministrationDetails />
          </AuthGuard>
        )
      },
      {
        path: '/administrations/:pammId/investments/:investmentId/details',
        element: (
          <AuthGuard>
            <AdministrationInvestmentDetails />
          </AuthGuard>
        )
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <Home />
          </AuthGuard>
        )
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
