import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')();

const Logo = (props) => (
  <LogoRoot
    height="54"
    version="1.1"
    viewBox="0 0 229.33 134.22"
    width="54"
    {...props}
  >
    <title>W7BT</title>
    <g>
      <polygon
        points="11.24 18.86 47.8 18.86 47.8 31.36 36.8 31.36 54.24 92.86 71.02 31.36 59.91 31.36 59.91 18.86 103.8 18.86 103.8 31.36 92.35 31.36 110.02 92.86 126.91 31.36 115.57 31.36 115.57 18.86 151.13 18.86 151.13 31.36 140.02 31.36 115.8 115.36 102.91 115.36 81.19 41.64 60.46 115.36 47.24 115.36 22.91 31.36 11.24 31.36 11.24 18.86"
        fill="#d7b679"
      />
      <path
        d="M178.09,102.05H165.35v12.07h37.56V102.19h-11c5.48-54.51,26.22-72.14,26.22-72.14V18.86h-59.7V44.34h12.07V31.53h31.46S180.46,64.71,178.09,102.05Z"
        fill="#d7b679"
      />

    </g>
  </LogoRoot>
);

export default Logo;
