import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  alerts: []
};

const slice = createSlice({
  name: 'myAlerts',
  initialState,
  reducers: {
    getAlerts(state, action) {
      const alerts = action.payload;
      state.alerts = alerts;
    },
    resetAlerts(state) {
      state.alerts = initialState.alerts;
    }
  }
});

export const { reducer } = slice;

export const getAlerts = () => async (dispatch) => {
  const response = await axios.get('https://manager.w7bt.com/v1/pamms/alerts');
  dispatch(slice.actions.getAlerts(response.data));
};

export const resetAlerts = () => async (dispatch) => {
  dispatch(slice.actions.resetAlerts());
};

export default slice;
