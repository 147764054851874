import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Divider,
  Hidden,
  IconButton,
  Link,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';
import AccountPopover from './AccountPopover';
import useAuth from '../hooks/useAuth';
import NotificationsPopover from './NotificationsPopover';

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;
  const { isAuthenticated, user } = useAuth();

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        {
          user && user.is_investor ? (
            <Hidden mdDown>
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/pamms/investments"
                underline="none"
                variant="button"
              >
                INVESTMENTS
              </Link>
              <Divider
                orientation="vertical"
                sx={{
                  height: 32,
                  mx: 2
                }}
              />
            </Hidden>
          ) : ''
        }
        {
          user && user.is_manager ? (
            <Hidden mdDown>
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/pamms/managements"
                underline="none"
                variant="button"
              >
                MANAGEMENTS
              </Link>
              <Divider
                orientation="vertical"
                sx={{
                  height: 32,
                  mx: 2
                }}
              />
            </Hidden>
          ) : ''
        }
        {
          user && user.is_admin ? (
            <Hidden mdDown>
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/pamms/administrations"
                underline="none"
                variant="button"
              >
                ADMINISTRATIONS
              </Link>
              <Divider
                orientation="vertical"
                sx={{
                  height: 32,
                  mx: 2
                }}
              />
            </Hidden>
          ) : ''
        }
        {
          isAuthenticated && user && (user.is_admin) ? (
            <Box sx={{ ml: 1 }}>
              <NotificationsPopover />
            </Box>
          ) : ('')
        }
        {
          isAuthenticated ? (
            <Box sx={{ ml: 2 }}>
              <AccountPopover />
            </Box>
          ) : (
            ''
          )
        }

      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
