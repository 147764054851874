import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  managements: [],
  managementNotifications: {},
  managementDetail: {},
  managementOpenPositions: [],
  managementClosedPositions: [],
  managementHistory: [],
  managementInvestors: [],
  managementRequests: [],
  managementFreezeRequests: [],
  tableManagementRequests: [],
  tableManagementFreezeRequests: [],
  managementOffers: []
};

const slice = createSlice({
  name: 'myManagements',
  initialState,
  reducers: {
    getManagements(state, action) {
      const managements = action.payload;
      state.managements = managements;
    },
    getManagementNotifications(state, action) {
      const managementNotifications = action.payload;
      state.managementNotifications = managementNotifications;
    },
    getManagementDetail(state, action) {
      const managementDetail = action.payload;
      state.managementDetail = managementDetail;
    },
    getManagementClosedPositions(state, action) {
      const managementClosedPositions = action.payload;
      state.managementClosedPositions = managementClosedPositions;
    },
    getManagementOpenPositions(state, action) {
      const managementOpenPositions = action.payload;
      state.managementOpenPositions = managementOpenPositions;
    },
    getManagementHistory(state, action) {
      const managementHistory = action.payload;
      state.managementHistory = managementHistory;
    },
    getManagementInvestors(state, action) {
      const managementInvestors = action.payload;
      state.managementInvestors = managementInvestors;
    },
    getManagementRequests(state, action) {
      const managementRequests = action.payload;
      state.managementRequests = managementRequests;
    },
    getManagementFreezeRequests(state, action) {
      const managementFreezeRequests = action.payload;
      state.managementFreezeRequests = managementFreezeRequests;
    },
    getTableManagementRequests(state, action) {
      const tableManagementRequests = action.payload;
      state.tableManagementRequests = tableManagementRequests;
    },
    getTableManagementFreezeRequests(state, action) {
      const tableManagementFreezeRequests = action.payload;
      state.tableManagementFreezeRequests = tableManagementFreezeRequests;
    },
    getManagementOffers(state, action) {
      const managementOffers = action.payload;
      state.managementOffers = managementOffers;
    },
    resetManagementDetails(state) {
      state.managementDetail = initialState.managementDetail;
      state.managementOpenPositions = initialState.managementOpenPositions;
      state.managementOpenPositions = initialState.managementOpenPositions;
      state.managementClosedPositions = initialState.managementClosedPositions;
      state.managementHistory = initialState.managementHistory;
      state.tableManagementRequests = initialState.tableManagementRequests;
      state.managementInvestors = initialState.managementInvestors;
      state.managementRequests = initialState.managementRequests;
      state.managementFreezeRequests = initialState.managementFreezeRequests;
      state.managementOffers = initialState.managementOffers;
    },
    resetManagementRequests(state) {
      state.managementRequests = initialState.managementRequests;
    },
    resetManagementFreezeRequests(state) {
      state.managementFreezeRequests = initialState.managementFreezeRequests;
    },
    resetTableManagementRequests(state) {
      state.tableManagementRequests = initialState.tableManagementRequests;
    },
    resetTableManagementFreezeRequests(state) {
      state.tableManagementFreezeRequests = initialState.tableManagementFreezeRequests;
    }
  }
});

export const { reducer } = slice;

export const getManagements = (onLoading) => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/pamms/managements');
    dispatch(slice.actions.getManagements(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getManagements(initialState.managements));
    onLoading();
  }
};

export const getManagementNotifications = () => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/pamms/managements/notifications');
    dispatch(slice.actions.getManagementNotifications(response.data));
  } catch {
    dispatch(slice.actions.getManagementNotifications(initialState.managementNotifications));
  }
};

export const getManagementDetail = (pammId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/detail`);
  dispatch(slice.actions.getManagementDetail(response.data));
};

export const getManagementClosedPositions = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/positions/closed`);
    dispatch(slice.actions.getManagementClosedPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getManagementClosedPositions(initialState.managementClosedPositions));
    onLoading();
  }
};

export const getManagementOpenPositions = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/positions/open`);
    dispatch(slice.actions.getManagementOpenPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getManagementOpenPositions(initialState.managementOpenPositions));
    onLoading();
  }
};

export const getManagementHistory = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/history`);
    dispatch(slice.actions.getManagementHistory(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getManagementHistory(initialState.managementHistory));
    onLoading();
  }
};

export const getManagementInvestors = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/investors`);
    dispatch(slice.actions.getManagementInvestors(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getManagementInvestors(initialState.managementInvestors));
    onLoading();
  }
};

export const getManagementRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getManagementRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetManagementRequests(initialState.managementRequests));
    onLoading();
  }
};

export const getManagementFreezeRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/freezes/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getManagementFreezeRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetManagementFreezeRequests());
    onLoading();
  }
};

export const getTableManagementRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getTableManagementRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetTableManagementRequests());
    onLoading();
  }
};

export const getTableManagementFreezeRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/freezes/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getTableManagementFreezeRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetTableManagementFreezeRequests());
    onLoading();
  }
};

export const getManagementOffers = (pammId, onLoading) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/managements/offers`);
  dispatch(slice.actions.getManagementOffers(response.data));
  onLoading();
};

export const resetManagementDetails = () => async (dispatch) => {
  dispatch(slice.actions.resetManagementDetails());
};

export default slice;
