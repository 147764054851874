import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  serverStatistics: {},
  administrations: [],
  administrationNotifications: [],
  administrationDetail: {},
  administrationOpenPositions: [],
  administrationClosedPositions: [],
  administrationHistory: [],
  administrationInvestors: [],
  administrationRequests: [],
  administrationFreezeRequests: [],
  tableAdministrationRequests: [],
  tableAdministrationFreezeRequests: [],
  administrationInvestmentDetail: {},
  administrationInvestmentOpenPositions: [],
  administrationInvestmentClosedPositions: [],
  administrationInvestmentHistory: [],
  administrationInvestmentInvestors: [],
  administrationInvestmentRequests: [],
  administrationOffers: [],
  investors: []
};

const slice = createSlice({
  name: 'myAdministrations',
  initialState,
  reducers: {
    getServerStatistics(state, action) {
      const serverStatistics = action.payload;
      state.serverStatistics = serverStatistics;
    },
    getAdministrations(state, action) {
      const administrations = action.payload;
      state.administrations = administrations;
    },
    getAdministrationNotifications(state, action) {
      const administrationNotifications = action.payload;
      state.administrationNotifications = administrationNotifications;
    },
    getAdministrationDetail(state, action) {
      const administrationDetail = action.payload;
      state.administrationDetail = administrationDetail;
    },
    getAdministrationClosedPositions(state, action) {
      const administrationClosedPositions = action.payload;
      state.administrationClosedPositions = administrationClosedPositions;
    },
    getAdministrationOpenPositions(state, action) {
      const administrationOpenPositions = action.payload;
      state.administrationOpenPositions = administrationOpenPositions;
    },
    getAdministrationHistory(state, action) {
      const administrationHistory = action.payload;
      state.administrationHistory = administrationHistory;
    },
    getAdministrationInvestors(state, action) {
      const administrationInvestors = action.payload;
      state.administrationInvestors = administrationInvestors;
    },
    getAdministrationRequests(state, action) {
      const administrationRequests = action.payload;
      state.administrationRequests = administrationRequests;
    },
    getAdministrationFreezeRequests(state, action) {
      const administrationFreezeRequests = action.payload;
      state.administrationFreezeRequests = administrationFreezeRequests;
    },
    getTableAdministrationRequests(state, action) {
      const tableAdministrationRequests = action.payload;
      state.tableAdministrationRequests = tableAdministrationRequests;
    },
    getTableAdministrationFreezeRequests(state, action) {
      const tableAdministrationFreezeRequests = action.payload;
      state.tableAdministrationFreezeRequests = tableAdministrationFreezeRequests;
    },
    getInvestors(state, action) {
      const investors = action.payload;
      state.investors = investors;
    },
    getAdministrationOffers(state, action) {
      const administrationOffers = action.payload;
      state.administrationOffers = administrationOffers;
    },
    resetAdministrationDetails(state) {
      state.administrationDetail = initialState.administrationDetail;
      state.administrationOpenPositions = initialState.administrationOpenPositions;
      state.administrationOpenPositions = initialState.administrationOpenPositions;
      state.administrationClosedPositions = initialState.administrationClosedPositions;
      state.administrationHistory = initialState.administrationHistory;
      state.administrationInvestors = initialState.administrationInvestors;
      state.administrationRequests = initialState.administrationRequests;
      state.tableAdministrationRequests = initialState.tableAdministrationRequests;
      state.administrationOffers = initialState.administrationOffers;
      state.administrationRequests = initialState.administrationRequests;
      state.administrationFreezeRequests = initialState.administrationFreezeRequests;
    },
    resetAdministrationRequests(state) {
      state.administrationRequests = initialState.administrationRequests;
    },
    resetAdministrationFreezeRequests(state) {
      state.administrationFreezeRequests = initialState.administrationFreezeRequests;
    },
    resetTableAdministrationRequests(state) {
      state.tableAdministrationRequests = initialState.tableAdministrationRequests;
    },
    resetTableAdministrationFreezeRequests(state) {
      state.tableAdministrationFreezeRequests = initialState.tableAdministrationFreezeRequests;
    },
    getAdministrationInvestmentDetail(state, action) {
      const administrationInvestmentDetail = action.payload;
      state.administrationInvestmentDetail = administrationInvestmentDetail;
    },
    getAdministrationInvestmentClosedPositions(state, action) {
      const administrationInvestmentClosedPositions = action.payload;
      state.administrationInvestmentClosedPositions = administrationInvestmentClosedPositions;
    },
    getAdministrationInvestmentOpenPositions(state, action) {
      const administrationInvestmentOpenPositions = action.payload;
      state.administrationInvestmentOpenPositions = administrationInvestmentOpenPositions;
    },
    getAdministrationInvestmentHistory(state, action) {
      const administrationInvestmentHistory = action.payload;
      state.administrationInvestmentHistory = administrationInvestmentHistory;
    },
    getAdministrationInvestmentRequests(state, action) {
      const administrationInvestmentRequests = action.payload;
      state.administrationInvestmentRequests = administrationInvestmentRequests;
    },
    resetAdministrationInvestmentDetails(state) {
      state.administrationInvestmentDetail = initialState.administrationInvestmentDetail;
      state.administrationInvestmentOpenPositions = initialState.administrationInvestmentOpenPositions;
      state.administrationInvestmentOpenPositions = initialState.administrationInvestmentOpenPositions;
      state.administrationInvestmentClosedPositions = initialState.administrationInvestmentClosedPositions;
      state.administrationInvestmentHistory = initialState.administrationInvestmentHistory;
      state.administrationInvestmentInvestors = initialState.administrationInvestmentInvestors;
      state.administrationInvestmentRequests = initialState.administrationInvestmentRequests;
    }
  }
});

export const { reducer } = slice;

export const getServerStatistics = (onLoading) => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/server/statistics');
    dispatch(slice.actions.getServerStatistics(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getServerStatistics(initialState.serverStatistics));
    onLoading();
  }
};

export const getAdministrations = (onLoading) => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/pamms/administrations');
    dispatch(slice.actions.getAdministrations(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrations(initialState.administrations));
    onLoading();
  }
};

export const getAdministrationNotifications = () => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/pamms/administrations/notifications');
    dispatch(slice.actions.getAdministrationNotifications(response.data));
  } catch {
    dispatch(slice.actions.getAdministrationNotifications(initialState.administrationNotifications));
  }
};

export const getAdministrationDetail = (pammId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/detail`);
  dispatch(slice.actions.getAdministrationDetail(response.data));
};

export const getAdministrationClosedPositions = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/positions/closed`);
    dispatch(slice.actions.getAdministrationClosedPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationClosedPositions(initialState.administrationClosedPositions));
    onLoading();
  }
};

export const getAdministrationOpenPositions = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/positions/open`);
    dispatch(slice.actions.getAdministrationOpenPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationOpenPositions(initialState.administrationOpenPositions));
    onLoading();
  }
};

export const getAdministrationHistory = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/history`);
    dispatch(slice.actions.getAdministrationHistory(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationHistory(initialState.administrationHistory));
    onLoading();
  }
};

export const getAdministrationInvestors = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/investors`);
    dispatch(slice.actions.getAdministrationInvestors(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationInvestors(initialState.administrationInvestors));
    onLoading();
  }
};

export const getAdministrationRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getAdministrationRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetAdministrationRequests());
    onLoading();
  }
};

export const getAdministrationFreezeRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/freezes/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getAdministrationFreezeRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetAdministrationFreezeRequests());
    onLoading();
  }
};

export const getTableAdministrationRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getTableAdministrationRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetTableAdministrationRequests());
    onLoading();
  }
};

export const getTableAdministrationFreezeRequests = (pammId, status, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/freezes/requests${status.length > 0 ? `?status=${status}` : ''}`);
    dispatch(slice.actions.getTableAdministrationFreezeRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.resetTableAdministrationFreezeRequests());
    onLoading();
  }
};

export const getAdministrationOffers = (pammId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/offers`);
    dispatch(slice.actions.getAdministrationOffers(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationOffers(initialState.administrationOffers));
    onLoading();
  }
};

export const getInvestors = (onLoading) => async (dispatch) => {
  try {
    const response = await axios.get('https://manager.w7bt.com/v1/pamms/administrations/investors');
    dispatch(slice.actions.getInvestors(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getInvestors(initialState.investors));
    onLoading();
  }
};

export const resetAdministrationDetails = () => async (dispatch) => {
  dispatch(slice.actions.resetAdministrationDetails());
};

export const getAdministrationInvestmentDetail = (pammId, investmentId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/investments/${investmentId}/detail`);
  dispatch(slice.actions.getAdministrationInvestmentDetail(response.data));
};

export const getAdministrationInvestmentClosedPositions = (pammId, investmentId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/investments/${investmentId}/positions/closed`);
    dispatch(slice.actions.getAdministrationInvestmentClosedPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationInvestmentClosedPositions(initialState.administrationInvestmentClosedPositions));
    onLoading();
  }
};

export const getAdministrationInvestmentOpenPositions = (pammId, investmentId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/investments/${investmentId}/positions/open`);
    dispatch(slice.actions.getAdministrationInvestmentOpenPositions(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationInvestmentOpenPositions(initialState.administrationInvestmentOpenPositions));
    onLoading();
  }
};

export const getAdministrationInvestmentHistory = (pammId, investmentId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/investments/${investmentId}/history`);
    dispatch(slice.actions.getAdministrationInvestmentHistory(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationInvestmentHistory(initialState.administrationInvestmentHistory));
    onLoading();
  }
};

export const getAdministrationInvestmentRequests = (pammId, investmentId, onLoading) => async (dispatch) => {
  try {
    const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/administrations/investments/${investmentId}/requests`);
    dispatch(slice.actions.getAdministrationInvestmentRequests(response.data));
    onLoading();
  } catch {
    dispatch(slice.actions.getAdministrationInvestmentRequests(initialState.administrationInvestmentRequests));
    onLoading();
  }
};

export const resetAdministrationInvestmentDetails = () => async (dispatch) => {
  dispatch(slice.actions.resetAdministrationInvestmentDetails());
};

export default slice;
