import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.data === 'Unauthorized') {
      window.location.href = '/';
      return error;
    }
    return Promise.reject((error.response && error.response.data));
  });

export default axiosInstance;
