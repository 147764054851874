import { createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  pamms: [],
  pammDetail: {},
  pammOffers: [],
  pammIndicatorTWRR: [],
  pammIndicatorDrawdown: []
};

const slice = createSlice({
  name: 'pamms',
  initialState,
  reducers: {
    getPamms(state, action) {
      const pamms = action.payload;
      state.pamms = pamms;
    },
    getPammDetail(state, action) {
      const pammDetail = action.payload;
      state.pammDetail = pammDetail;
    },
    getPammOffers(state, action) {
      const pammOffers = action.payload;
      state.pammOffers = pammOffers;
    },
    getPammIndicatorTWRR(state, action) {
      const pammIndicatorTWRR = action.payload;
      state.pammIndicatorTWRR = pammIndicatorTWRR;
    },
    getPammIndicatorDrawdown(state, action) {
      const pammIndicatorDrawdown = action.payload;
      state.pammIndicatorDrawdown = pammIndicatorDrawdown;
    },
    reset(state) {
      state.pamms = initialState.pamms;
      state.pammDetail = initialState.pammDetail;
      state.pammOffers = initialState.pammOffers;
      state.pammIndicatorTWRR = initialState.pammIndicatorTWRR;
      state.pammIndicatorDrawdown = initialState.pammIndicatorDrawdown;
    },
    resetPammIndicatorTWRR(state) {
      state.pammIndicatorTWRR = initialState.pammIndicatorTWRR;
    },
    resetPammIndicatorDrawdown(state) {
      state.pammIndicatorDrawdown = initialState.pammIndicatorDrawdown;
    }
  }
});

export const { reducer } = slice;

export const getPamms = (onLoading) => async (dispatch) => {
  const response = await axios.get('https://manager.w7bt.com/v1/pamms');
  dispatch(slice.actions.getPamms(response.data));
  onLoading();
};

export const getPammDetail = (pammId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}`);
  dispatch(slice.actions.getPammDetail(response.data));
};

export const getPammOffers = (pammId, onLoading) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/offers`);
  dispatch(slice.actions.getPammOffers(response.data));
  onLoading();
};

export const getPammIndicatorTWRR = (pammId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/indicators/twrr`);
  dispatch(slice.actions.getPammIndicatorTWRR(response.data));
};

export const getPammIndicatorDrawdown = (pammId) => async (dispatch) => {
  const response = await axios.get(`https://manager.w7bt.com/v1/pamms/${pammId}/indicators/drawdown`);
  dispatch(slice.actions.getPammIndicatorDrawdown(response.data));
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

export const resetPammIndicatorTWRR = () => async (dispatch) => {
  dispatch(slice.actions.resetPammIndicatorTWRR());
};

export const resetPammIndicatorDrawdown = () => async (dispatch) => {
  dispatch(slice.actions.resetPammIndicatorDrawdown());
};

export default slice;
