import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, Link } from '@material-ui/core';
import Logo from './Logo';
import useAuth from '../hooks/useAuth';

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: 256
          }
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          {
            user && user.is_investor ? (
              <Box
                sx={{
                  display: 'flex',
                  pb: 1,
                  pt: 1
                }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/pamms/investments"
                  underline="none"
                  variant="body1"
                >
                  Investments
                </Link>
              </Box>
            ) : ''
          }
          {
            user && user.is_manager ? (
              <Box
                sx={{
                  display: 'flex',
                  pb: 1,
                  pt: 1
                }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/pamms/managements"
                  underline="none"
                  variant="body1"
                >
                  Managements
                </Link>
              </Box>
            ) : ''
          }
          {
            user && user.is_admin ? (
              <Box
                sx={{
                  display: 'flex',
                  pb: 1,
                  pt: 1
                }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/pamms/administrations"
                  underline="none"
                  variant="body1"
                >
                  Administrations
                </Link>
              </Box>
            ) : ''
          }
        </Box>
      </Drawer>
    </Hidden>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
